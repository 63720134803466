/*eslint-disable */
// import CustomReportModel from '../../Model/CustomReport'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let CustomReports = {
  /**
   * customReportList
   */
  async customReportList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "custom_report_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at customReportList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * customReportList
  */
  async customReportDownload (context, custReportObj, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();

      post_data.append('cust_report_id', custReportObj.cust_report_id)
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "custom_report_download", post_data)
      .then(async response => {
        return response
      });
    }
    catch(err){
      console.error('Exception occurred at customReportDownload() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * customReportView
   */
  async customReportView (context, customReportId) {
    try {      
      let post_data = new FormData();
      post_data.append('cust_report_id', customReportId);
      return await request.curl(context, "custom_report_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at customReportView() and Exception:",err.message)
    }    
  },

  /**
   * customReportAdd
   */
  async customReportAdd (context, customReportObj) {
    try{
      let post_data = new FormData();
    
      for (let key in customReportObj) {
        post_data.append(key, customReportObj[key]);
      }

      return await request.curl(context, "custom_report_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at customReportAdd() and Exception:',err.message)
    }
  },

  /**
   * customReportEdit
   */
  async customReportEdit (context, customReportObj) {
    try{
      let post_data = new FormData();
    
      for (let key in customReportObj) {
        post_data.append(key, customReportObj[key]);
      }

      return await request.curl(context, "custom_report_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at customReportEdit() and Exception:',err.message)
    }
  },

  /**
   * customReportDelete
   */
  async customReportDelete (context, customReportId) {
    try{
      let post_data = new FormData();
      
      post_data.append('cust_report_id', customReportId);

      return await request.curl(context, "custom_report_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at customReportDelete() and Exception:',err.message)
    }
  }
}

export {
  CustomReports
}
