/*eslint-disable */
<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationcust_report_name">
                    {{cvCustReportNameLabel}}</label
                  >
                  <input
                    v-model="vmCustomReportFormData.cust_report_name"
                    type="text"
                    class="form-control"
                    required
                  />
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcust_report_description">
                    {{cvCustReportDescriptionLabel}}</label
                  >
                  <textarea v-model="vmCustomReportFormData.cust_report_des" type="text" class="form-control" required ></textarea>
              </div>
                <div class="col-md-12 mb-3">
                  <label for="validationcust_report_query">
                    {{cvCustReportQueryLabel}}</label>
                  <textarea v-model="vmCustomReportFormData.cust_report_query" type="text" class="form-control" required ></textarea>
              </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="customReportAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
              <div class="note">
                <p>AVAILABLE MACROS: START_DATE, END_DATE
                  Query Examples:<br>
                  <b>1.</b> SELECT * FROM pay_transactions WHERE created_on BETWEEN "START_DATE" AND "END_DATE";<br>
                  <b>2.</b> SELECT * FROM pay_subscription_plan WHERE subs_plan_id LIKE "%GPATH%";</p>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { CustomReports } from "../../../FackApi/api/CustomReport"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "CustomReportAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiName: "custom_report_add",
      cvCardTitle: "Add Custom Report",
      cvSubmitBtn: "Add",
      cvCustReportNameLabel: "Report name",
      cvCustReportDescriptionLabel: "Report description",
      cvCustReportQueryLabel: "Report query",
      showToast: false,
      toastMsg: null,
      toastVariant: "Default",
      cvLoadingStatus: false,
      toastTitle: "Custom Report Added",
      vmCustomReportFormData: Object.assign({}, this.initFormData()),
      vmCustomReportDesc: null,
      vmCustomReportType: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "cust_report_name": "",
        "cust_report_id": "",
        "cust_report_des": "",
        "cust_report_query": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCustomReportFormData) {
          if (!this.vmCustomReportFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * customReportAdd
     */
    async customReportAdd () {
      let custReportQuery = this.vmCustomReportFormData.cust_report_query ? this.vmCustomReportFormData.cust_report_query.toLowerCase() : null
      if (!custReportQuery) {
        this.toastMsg = "Missing Query"
        this.toastVariant = "danger"
        this.showToast = true
        return false
      }

      if (custReportQuery && !custReportQuery.includes("select") &&
        (custReportQuery.includes("update") ||
          custReportQuery.includes("alter") ||
          custReportQuery.includes("delete")
        )
      ) {
        // only Select query is allowed
        this.toastMsg = "Only SELECT Query operation is allowed. Other Operations like UPDATE, DELETE etc are not allowed"
        this.toastVariant = "danger"
        this.showToast = true
        return false
      }

      try {
        this.cvLoadingStatus = true
        let customReportAddResp = await CustomReports.customReportAdd(this, this.vmCustomReportFormData)
        await ApiResponse.responseMessageDisplay(this, customReportAddResp)
        if (customReportAddResp && !customReportAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCustomReportAddModal", customReportAddResp.resp_data)
        }
        this.vmCustomReportFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at customReportAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
.note{
  font-size: 11px;
  font-style: italic;
}
textarea {
  min-height: 150px;
  line-height: 27px !important;
}
</style>
